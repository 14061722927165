.capture-screen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
  font-family: sans-serif;
  padding: 20px 15px;
  width: 100%;
  color: #323130;

}


.capture-screen .modal-container {
  position: relative;
  margin: auto;
  background-color: #ffffff;
  padding: 20px 64px;
  width: 800px;
  max-height: 90%;
  overflow: hidden;
  overflow-y: auto;
}

.capture-screen ul {
  list-style-type: none;
  display: flex;
}

.capture-screen .screen-shot-button {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  position: absolute;
  top: 12px;
  right: 42px;
}

.capture-screen .close-button {
  display: flex;
  justify-content: flex-end;
  height: 50px;
  position: absolute;
  top: 12px;
  right: 12px;
}

.capture-screen .header {
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 20px;
  margin-bottom: 10px;
}

.capture-screen .header h3 {
  font-size: 14px;
  margin-left: 5px;
}

.capture-screen .subheader {
  height: 54px;
  font-size: 14px;
  display: flex;
  width: 100%;
}

.capture-screen .left-circle {
  display: flex;
  align-items: center;

}

.capture-screen .circle {
  display: flex;
  height: 50px;
  width: 50px;
  background-color: #881798;
  border-radius: 50%;
}

.capture-screen .p-circle {
  background-color: #881798;
  color: #ffffff;
  margin: auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  
}

.capture-screen .text-subheader {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.capture-screen .up {
  height: 27px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px;
}

.capture-screen .down {
  height: 27px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px;

}

.capture-screen .blue-arrows li {
  padding: 4px;
}

.capture-screen .files {
  display: flex;
  flex-wrap: wrap;
}

.capture-screen .file-item {
  margin-right: 15px;
  margin-top: 15px;
  border: 1px gray solid;
  display: flex;
  align-items: center;
}

.capture-screen .file-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  margin-right: 12px;

}

.capture-screen .sub-file {
  font-size: 12px;
  line-height: 12px;
  height: 40px;
}

.capture-screen .sub-file ul {
  margin: 0;
  padding: 0;
}

.capture-screen .sub-file li {
  margin-right: 15px;
}

.capture-screen .cloud {
  padding-left: 0;
}